import React, { createRef, StyleHTMLAttributes } from "react";
import { Navigate, NavLink, redirect} from 'react-router-dom';
import { Component } from "react";
import Error404 from '../../assets/img/error/404.svg'


type Props = {};

type State = {
  
};

export default class Authentication404error extends Component<Props, State> {
  

  constructor(props: Props) {
    super(props);
 
    
    this.state = {
      
    };
  }



 

  componentDidMount(){
     
  }

  render(){

    const {} = this.state;


      
    return (

     <>
            {/*<div className="back-to-home rounded d-none d-sm-block">
              <a href="" className="back-button btn btn-icon btn-primary"><i data-feather="arrow-left" className="icons"></i></a>
            </div>*/}

        
        <section className="bg-home d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 text-center">
                        <img src={Error404} className="img-fluid" alt=""/>
                        <div className="text-uppercase mt-4 display-3">Oh ! no</div>
                        <div className="text-capitalize text-dark mb-4 error-page">Pagina non trovata</div>
                        {/*<p className="text-muted para-desc mx-auto">Start working with <span className="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>*/}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">  
                        {/*<a href="index.html" className="btn btn-outline-primary mt-4">Go Back</a>*/}
                        <NavLink to={''} className="btn btn-primary mt-4 ms-2">Ritorna alla home</NavLink>
                    </div>
                </div>
            </div>
        </section>
       
     </>

    )
  }
}