import React from "react";
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation ,Outlet,NavLink,Navigate} from 'react-router-dom';
import { Component } from "react";
import SideBar from "./sideBar";
import Header from "./header";
import { store } from "../../app/store";


type Props = {};

type State = {
 sideOpen:boolean
};

export default class DashboardLayout extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    

    this.state = {
     sideOpen:false
    }

   store.subscribe(() =>{
    this.setState({
      sideOpen: store.getState().sidebar.open
    })
   })

  }

   

  componentDidMount(){
  }

  render(){


    return (
      <div className={this.state.sideOpen ? "page-wrapper toggled" : "page-wrapper"} >
         <SideBar/>
           <main className="page-content">
             <Outlet/>
             <Header/>
           </main>
      </div> 
   
    )
  }
}