import React from 'react';


  export const SidebarUser = [
    {
        title: "Profilo",
        icon: "ti ti-user me-2",
        path: "../dashboard/user/profile"
    }
  ];


  export const SidebarEmployee = [
    {
        title: "Profilo",
        icon: "ti ti-user me-2",
        path: "../dashboard/employee/profile"
    }
  ];



  export const SidebarAdmin = [
    {
        title: "Profilo",
        icon: "ti ti-user me-2",
        path: "../dashboard/admin/profile"
    },
    {
        title: "Messaggi",
        icon: "ti ti-mail-opened me-2",
        path: "../dashboard/admin/messages"
    },
    {
        title: "Utenti",
        icon: "ti ti-user me-2",
        path: "../dashboard/admin/users"
    },
    {
        title: "Contenuto pagine",
        icon: "ti ti-file-info me-2",
        childrens: [
            {
                title: "Impostazioni generali",
                path: "../dashboard/admin/general-settings"
            },
            {
                title: "Home",
                path: "../dashboard/admin/home"
            },
            {
                title: "Chi sono",
                path: "../dashboard/admin/about-me"
            },
            {
                title: "Portfolio",
                path: "../dashboard/admin/portfolio"
            },
            {
                title: "Statistiche",
                path: "../dashboard/admin/statistics"
            },
            {
                title: "Notizie",
                path: "../dashboard/admin/news"
            }
        ]
    }

  ];


  