import React, { createRef, StyleHTMLAttributes } from "react";
import { Component } from "react";
import * as yup from "yup";
import { Button, Form, Input, Skeleton, Switch, Tabs, TabsProps } from "antd";
import aboutmeService from "../../../api/page/aboutme/aboutme.service";
import { Field, Formik } from "formik";
import * as Forms from "formik";
import PreviewImage from "../element/previewImage";
import homeService from "../../../api/page/home/home.service";
import pageService from "../../../api/page/page.service";

type Props = {};

type State = {
  message: string;
  successful: boolean;
  updateid: number;
  loading: boolean;
  home: boolean;
  aboutme: boolean;
  services: boolean;
  portfolio: boolean;
  statistics: boolean;
  news: boolean;
  contactme: boolean;
};

export default class GeneralSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      successful: false,
      updateid: 1,
      loading: true,
      home:true,
      aboutme:true,
      services:true,
      portfolio:true,
      statistics:true,
      news:true,
      contactme:true
    };
  }

  

  getSettings = async () => {
    pageService.getSettings().then(
      (response) => {

     if(response.data[0]){
        this.setState({
          home: response.data[0].home,
          aboutme: response.data[0].aboutme,
          services: response.data[0].services,
          portfolio: response.data[0].portfolio,
          statistics: response.data[0].statistics,
          news: response.data[0].news,
          contactme: response.data[0].contactme
        });
     }

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  updateSettings = async (values) => {
    this.setState({
      message: "",
      successful: false,
    });

    pageService
      .updateSettings(
        this.state.updateid,
        values.home,
        values.aboutme,
        values.services,
        values.portfolio,
        values.statistics,
        values.news,
        values.contactme
      )
      .then(
        (response) => {
          this.setState({
            message: "Impostazioni salvate",
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };





  componentDidMount() {
    this.getSettings();
  }

  render() {
    const { message, successful } = this.state;


    const items: TabsProps["items"] = [
      {
        key: "1",
        label: `Impostazioni`,
        children: (
          <>
            {this.state.loading ? (
              <Skeleton loading={this.state.loading} active></Skeleton>
            ) : (
              <Form
                onFinish={this.updateSettings}
                initialValues={{
                   home: this.state.home,
                   aboutme: this.state.aboutme,
                   services: this.state.services,
                   portfolio: this.state.portfolio,
                   statistics: this.state.statistics,
                   news: this.state.news,
                   contactme: this.state.contactme
                }}
              >
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success alert-dismissible fade show"
                          : "alert alert-danger alert-dismissible fade show "
                      }
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>

                      {message}
                    </div>
                  </div>
                )}
                
                <div className="mb-3">
                  <Form.Item name="home" label="Abilita Home">
                    <Switch
                      checked={this.state.home}
                      onChange={() => {
                        this.setState({
                          home: !this.state.home,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="aboutme" label="Abilita Chi sono">
                    <Switch
                      checked={this.state.aboutme}
                      onChange={() => {
                        this.setState({
                          aboutme: !this.state.aboutme,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="services" label="Abilita Servizi">
                    <Switch
                      checked={this.state.services}
                      onChange={() => {
                        this.setState({
                          services: !this.state.services,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="portfolio" label="Abilita Portfolio">
                    <Switch
                      checked={this.state.portfolio}
                      onChange={() => {
                        this.setState({
                          portfolio: !this.state.portfolio,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="statistics" label="Abilita statistiche">
                    <Switch
                      checked={this.state.statistics}
                      onChange={() => {
                        this.setState({
                          statistics: !this.state.statistics,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="news" label="Abilita news">
                    <Switch
                      checked={this.state.news}
                      onChange={() => {
                        this.setState({
                          news: !this.state.news,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="contactme" label="Abilita Contattami ">
                    <Switch
                      checked={this.state.contactme}
                      onChange={() => {
                        this.setState({
                          contactme: !this.state.contactme,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "30px" }}
                  >
                    Salva
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ),
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Impostazioni generali</h5>

              <nav
                aria-label="breadcrumb"
                className="d-inline-block mt-2 mt-sm-0"
              >
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Impostazioni generali
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row" style={{ marginTop: "30px" }}>
              <Tabs defaultActiveKey="2" items={items} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
