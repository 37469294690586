import React, { createRef, StyleHTMLAttributes } from "react";
import { Component } from "react";
import * as yup from "yup";
import { Button, Form, Input, Skeleton, Switch, Tabs, TabsProps, Upload } from "antd";
import aboutmeService from "../../../api/page/aboutme/aboutme.service";
import { Field, Formik } from "formik";
import * as Forms from "formik";
import PreviewImage from "../element/previewImage";

type Props = {};

type State = {
  message: string;
  successful: boolean;
  updateid: number;
  loading: boolean;
  file: string,
  cvfile: string,
  description: string;
  birthdayinfo: boolean;
  birthday: string;
  ageinfo: boolean;
  age: string;
  locationinfo: boolean;
  location: string;
  interestsinfo: boolean;
  interests: string;
  studyinfo: boolean;
  study: string;
  degreeinfo: boolean;
  degree: string;
  emailinfo: boolean;
  email: string;
  phoneinfo: boolean;
  phone: string;
  downloadcv: boolean
};

export default class AboutMe extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      successful: false,
      updateid: 1,
      loading: true,
      file: "",
      cvfile: "",
      description: "",
      birthdayinfo: false,
      birthday: "",
      ageinfo: false,
      age: "",
      locationinfo: false,
      location: "",
      interestsinfo: false,
      interests: "",
      studyinfo: false,
      study: "",
      degreeinfo: false,
      degree: "",
      emailinfo: false,
      email: "",
      phoneinfo: false,
      phone: "",
      downloadcv: false
    };
  }

  validationSchema() {
    const supported_formats = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/svg",
      "image/ips",
      "image/psd",
      "image/ai",
    ];
    return yup.object().shape({
      image: yup
        .mixed()
        .nullable()
        .required("inserisci un file di immagine")
        .test(
          "type",
          "Il file caricato non è un'immaggine",
          (values) =>
            !values || (values && supported_formats.includes(values["type"]))
        ),
    });
  }

  validationCurriculum() {
    const supported_formats = [
      "application/pdf",
    ];
    return yup.object().shape({
      cvfile: yup
        .mixed()
        .nullable()
        .required("inserisci un file pdf")
        .test(
          "type",
          "Il file caricato non è un pdf",
          (values) =>
            !values || (values && supported_formats.includes(values["type"]))
        ),
    });
  }

  getSettings = async () => {
    aboutmeService.getSettings().then(
      (response) => {

        if (response.data[0]) {
          this.setState({
            description: response.data[0].description,
            birthdayinfo: response.data[0].birthdayinfo,
            birthday: response.data[0].birthday,
            ageinfo: response.data[0].ageinfo,
            age: response.data[0].age,
            locationinfo: response.data[0].locationinfo,
            location: response.data[0].location,
            interestsinfo: response.data[0].interestsinfo,
            interests: response.data[0].interests,
            studyinfo: response.data[0].studyinfo,
            study: response.data[0].study,
            degreeinfo: response.data[0].degreeinfo,
            degree: response.data[0].degree,
            emailinfo: response.data[0].emailinfo,
            email: response.data[0].email,
            phoneinfo: response.data[0].phoneinfo,
            phone: response.data[0].phone,
            downloadcv: response.data[0].downloadcv
          });
        }

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  updateSettings = async (values) => {
    this.setState({
      message: "",
      successful: false,
    });

    aboutmeService
      .updateSettings(
        this.state.updateid,
        values.description,
        values.birthdayinfo,
        values.birthday,
        values.ageinfo,
        values.age,
        values.locationinfo,
        values.location,
        values.interestsinfo,
        values.interests,
        values.studyinfo,
        values.study,
        values.degreeinfo,
        values.degree,
        values.emailinfo,
        values.email,
        values.phoneinfo,
        values.phone,
        values.downloadcv
      )
      .then(
        (response) => {
          this.setState({
            message: "Impostazioni salvate",
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };


  getImage = async () => {

    this.setState({
      file: ''
    })

    aboutmeService
      .getImage(
        this.state.updateid
      )
      .then(
        (response) => {

          this.setState({
            file: response.data
          })

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  }

  updateImage = async (formValue: { image }) => {

    const {
      image,
    } = formValue


    aboutmeService
      .updateImage(
        image,
        this.state.updateid
      )
      .then(
        (response) => {

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };

  getCV = async (options: any) => {

    

    aboutmeService
      .getCurriculum(
        this.state.updateid
      )
      .then(
        (response) => {


        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  }

  updateCurriculum = async (options: any) => {

 

    aboutmeService
      .updateCurriculum(
        options.file,
        this.state.updateid
      )
      .then(
        (response) => {
          options.onSuccess(response.data, options.file)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };

  componentDidMount() {
    this.getImage()
    this.getSettings();
  }

  render() {
    const { message, successful } = this.state;

    const initialValues = {
      image: this.state.file ? this.state.file : '',
    };

    const initialValuesCv = {
      cvfile: this.state.cvfile ? this.state.cvfile : '',
    };

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: `Immagine`,
        children: (
          <>
            <Forms.Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.updateImage}
            >
              {(formik) => (
                <Forms.Form>
                  <div className="d-grid me-md-4" style={{ width: '346px' }}>
                    <p className="text-muted">
                      Carica un'immagine qui. Clicca sul pulsante "Carica
                      immagine".
                    </p>
                    {formik.values.image != "" ? (
                      <PreviewImage file={formik.values.image} />
                    ) : null}
                    <input
                      type="file"
                      id="input-file"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={(event) => {
                        //console.log(event.target.files)
                        formik.setFieldValue("image", event.target.files![0]);
                      }}
                      className={
                        formik.touched.image && formik.errors.image
                          ? "form-control ps-5 is-invalid"
                          : "form-control ps-5"
                      }
                    />
                    {formik.touched.image && formik.errors.image ? (
                      <div className="invalid-feedback">
                        {formik.errors.image}
                      </div>
                    ) : null}
                    <label
                      className="btn-upload btn btn-primary mt-4"
                      htmlFor="input-file"
                    >
                      Carica immagine
                    </label>
                    <Button type="primary" htmlType="submit" style={{ width: '346px' }}>
                      Aggiungi
                    </Button>
                  </div>

                </Forms.Form>
              )}
            </Forms.Formik>
          </>
        ),
      },
      {
        key: "2",
        label: `Curriculum`,
        children: (
          <>
            <Upload
              style={{width:"300px"}}
              accept=".pdf"
              name="logo"
              multiple={false}
              listType="text"
              maxCount={1} 
              customRequest={this.updateCurriculum}
              beforeUpload={this.getCV}
            >
              <Button>
                Clicca per caricare un file
              </Button>
            </Upload>
          </>
        ),
      },
      {
        key: "3",
        label: `Impostazioni`,
        children: (
          <>
            {this.state.loading ? (
              <Skeleton loading={this.state.loading} active></Skeleton>
            ) : (
              <Form
                onFinish={this.updateSettings}
                initialValues={{
                  description: this.state.description,
                  birthdayinfo: this.state.birthdayinfo,
                  birthday: this.state.birthday,
                  ageinfo: this.state.ageinfo,
                  age: this.state.age,
                  locationinfo: this.state.locationinfo,
                  location: this.state.location,
                  interestsinfo: this.state.interestsinfo,
                  interests: this.state.interests,
                  studyinfo: this.state.studyinfo,
                  study: this.state.study,
                  degreeinfo: this.state.degreeinfo,
                  degree: this.state.degree,
                  emailinfo: this.state.emailinfo,
                  email: this.state.email,
                  phoneinfo: this.state.phoneinfo,
                  phone: this.state.phone,
                  downloadcv: this.state.downloadcv
                }}
              >
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success alert-dismissible fade show"
                          : "alert alert-danger alert-dismissible fade show "
                      }
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>

                      {message}
                    </div>
                  </div>
                )}
                <div className="mb-3" style={{ width: "300px" }}>
                  <label>Descrizione</label>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Inserisci una descrizione",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.TextArea placeholder="Inserisci una descrizione" />
                  </Form.Item>
                </div>
                <div className="mb-3">
                  <Form.Item name="birthdayinfo" label="Info nascita">
                    <Switch
                      checked={this.state.birthdayinfo}
                      onChange={() => {
                        this.setState({
                          birthdayinfo: !this.state.birthdayinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.birthdayinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Nato il:</label>
                    <Form.Item
                      name="birthday"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci la data di nascita",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci data di nascita" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="ageinfo" label="Info età">
                    <Switch
                      checked={this.state.ageinfo}
                      onChange={() => {
                        this.setState({
                          ageinfo: !this.state.ageinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.ageinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Età</label>
                    <Form.Item
                      name="age"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci l'età",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci l'età" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="locationinfo" label="Info locazione">
                    <Switch
                      checked={this.state.locationinfo}
                      onChange={() => {
                        this.setState({
                          locationinfo: !this.state.locationinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.locationinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Locazione</label>
                    <Form.Item
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci una locazione",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci una locazione" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="interestsinfo" label="Info interessi">
                    <Switch
                      checked={this.state.interestsinfo}
                      onChange={() => {
                        this.setState({
                          interestsinfo: !this.state.interestsinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.interestsinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Interessi</label>
                    <Form.Item
                      name="interests"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci interessi",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci interessi" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="studyinfo" label="Info studi">
                    <Switch
                      checked={this.state.studyinfo}
                      onChange={() => {
                        this.setState({
                          studyinfo: !this.state.studyinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.studyinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Studi</label>
                    <Form.Item
                      name="study"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci studi",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci studi" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="degreeinfo" label="Info laurea">
                    <Switch
                      checked={this.state.degreeinfo}
                      onChange={() => {
                        this.setState({
                          degreeinfo: !this.state.degreeinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.degreeinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Laurea</label>
                    <Form.Item
                      name="degree"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci la laurea",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci la laurea" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="emailinfo" label="Info email">
                    <Switch
                      checked={this.state.emailinfo}
                      onChange={() => {
                        this.setState({
                          emailinfo: !this.state.emailinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.emailinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci l'email",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci l'email" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="phoneinfo" label="Info telefono">
                    <Switch
                      checked={this.state.phoneinfo}
                      onChange={() => {
                        this.setState({
                          phoneinfo: !this.state.phoneinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.phoneinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Telefono</label>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un numero di telefono",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un numero di telefono" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="downloadcv" label="Permetti download curriculum">
                    <Switch
                      checked={this.state.downloadcv}
                      onChange={() => {
                        this.setState({
                          downloadcv: !this.state.downloadcv,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "30px" }}
                  >
                    Salva
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ),
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Profilo</h5>

              <nav
                aria-label="breadcrumb"
                className="d-inline-block mt-2 mt-sm-0"
              >
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Chi sono
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row" style={{ marginTop: "30px" }}>
              <Tabs defaultActiveKey="3" items={items} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
