import React from 'react';
import { Counter } from './features/counter/Counter';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import BypassLogin from './api/auth/bypass.login';
import Login from './components/authentication/login';
import ProtectedAdmin from './api/auth/protectedAdmin';
import Void from './components/common/void';
import DashboardLayout from './components/common/appLayout';
import ProtectedEmployee from './api/auth/protectedEmployee';
import ProtectedUser from './api/auth/protectedUser';
import Profile from './components/user /profile';
import Dashboard404error from './components/error/dashboard404';
import Messages from './components/admin/messages';
import Redirect from './components/common/authorization/redirect';
import Users from './components/admin/users';
import ConfirmAccount from './components/authentication/confirmAccount';
import ForgotPassword from './components/authentication/forgotPassword';
import VerifyOtp from './components/authentication/verifyOtp';
import ProtectResetPassword from './api/auth/protectResetpassword';
import ResetPassword from './components/authentication/resetPassword';
import Authentication404error from './components/error/authentication404';
import AboutMe from './components/admin/pages/aboutme';
import Home from './components/admin/pages/home';
import GeneralSettings from './components/admin/pages/generalSettings';
import Statistics from './components/admin/pages/statistics';
import News from './components/admin/pages/news';
import Portfolio from './components/admin/pages/portfolio';

const element = createBrowserRouter([
  {
    path: "*",
    element: <Authentication404error/>,
  },
  {
    path:"",
    element: <Navigate to={"/login"}/>
  },
  {
    path: "/login",
    element: <BypassLogin><Login/></BypassLogin>
    
  },
  {
    path: "/confirm-account",
    element: <ConfirmAccount/>
  },
  {
    path: "/forgot",
    element: <ForgotPassword/>
  },
  {
   path: "/verify",
   element: <VerifyOtp/>
  },
  {
   path: "/reset-password",
   element: <ProtectResetPassword><ResetPassword/></ProtectResetPassword>
  },
  {
    path: "/dashboard",
    element: <Redirect><DashboardLayout/></Redirect>,
    children: [
      {
        path: "*",
        element: <Dashboard404error/>,
      },
      {
        path:  "admin",
        element: <ProtectedAdmin><Void/></ProtectedAdmin>,
        children: [
          {
            path:  "profile",
            element: <Profile/>
          },
          {
            path:  "messages",
            element: <Messages/>
          },
          {
            path:  "users",
            element: <Users/>
          },
          {
            path:  "general-settings",
            element: <GeneralSettings/>
          },
          {
            path:  "home",
            element: <Home/>
          },
          {
            path:  "about-me",
            element: <AboutMe/>
          },
          {
            path:  "portfolio",
            element: <Portfolio/>
          },
          {
            path:  "statistics",
            element: <Statistics/>
          },
          {
            path:  "news",
            element: <News/>
          }
        ]
      },
      {
        path:  "employee",
        element: <ProtectedEmployee><Void/></ProtectedEmployee>,
        children: [
          {
            path:  "profile",
            element: <Profile/>
          }
        ]
      },
      {
        path:  "user",
        element: <ProtectedUser><Void/></ProtectedUser>,
        children: [
          {
            path:  "profile",
            element: <Profile/>
          }
        ]
      },
    ],
  },
  
])

 export default element
 