import React from "react";
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation ,Outlet,NavLink,Navigate} from 'react-router-dom';
import { Component } from "react";
import { SidebarAdmin, SidebarEmployee , SidebarUser } from './data/sidebarData';
import SidebarItem from "./sidebarItem";
import TokenService from "../../api/token.service";
import authService from "../../api/auth/auth.service";

type Props = {};

type State = {
  message:string,
  successful:boolean,
  logout:boolean,
  map: any[]
};

export default class SideBar extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

     this.handleLogout = this.handleLogout.bind(this)
     this.sidebarItem = this.sidebarItem.bind(this)
  

    this.state = {
      message:'',
      successful:false,
      logout:false,
      map: []
    }
  }

  handleLogout(){

    authService.logout(
    ).then(
      response => {
      
           TokenService.clear()

          this.setState({
            logout:true,
          })
        
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

    }

      
    );

  
  }

 sidebarItem = () =>{
    if(TokenService.getDataAccessToken().type == 'user'){
        this.setState ({
          map: SidebarUser
        })
      }

      if(TokenService.getDataAccessToken().type == 'employee'){
        this.setState ({
          map: SidebarEmployee
        })
      }
    
      if(TokenService.getDataAccessToken().type == 'admin'){
        this.setState ({
          map: SidebarAdmin
        })
      }
 }

 
 setActiveItem = () =>{
   
 }



  getCurrentUser = () =>{

  }

  componentDidMount(){
   this.sidebarItem()
  
  }

  render(){

    
    return (

        <>
         
         {this.state.logout ? <Navigate to='../../login' replace={true}/> : null}

           <nav id="sidebar" className="sidebar-wrapper sidebar-dark">
                <div className="sidebar-content" data-simplebar style={{height: "calc(100% - 60px);"}}>
                    <div className="sidebar-brand">
                        <a >
                            <img src="assets/images/logo-dark.png" height="24" className="logo-light-mode" alt=""/>
                            <img src="assets/images/logo-light.png" height="24" className="logo-dark-mode" alt=""/>
                            <span className="sidebar-colored">
                                <img src="assets/images/logo-light.png" height="24" alt=""/>
                            </span>
                        </a>
                    </div>

                   
        
                    <ul className="sidebar-menu">
                        {this.state.map.map((item, index) => <SidebarItem key={index} item={item} />) }
                    </ul>
                    
                </div>
                
                <ul className="sidebar-footer list-unstyled mb-0">
                    <li  className="list-inline-item mb-0">
                        <a onClick={this.handleLogout}  className="btn btn-icon btn-soft-light"><i className="ti ti-login"></i></a> <small className="text-muted fw-medium ms-1">Esci</small>
                    </li>
                </ul>
                
            </nav>
           
        </>
    )
  }
}