import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  ColumnsType,
  ColumnType,
  FilterConfirmProps,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { Button, Input, InputRef, Space, Table, TableProps, Tag } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../common/animation/skeletonTable";
import messageService from "../../../api/message/message.service";
import moment from "moment";
import { Modal } from "react-bootstrap";
import newsService from "../../../api/page/news/news.service";
import PreviewImage from "../element/previewImage";

type Props = {};

type State = {
  loading:boolean,
  confirm: boolean;
  successful: boolean;
  message: string;
  successfulAdd:boolean,
  messageAdd:string,
  successfulDelete:boolean,
  messageDelete:string,
  successfulUpdate:boolean,
  messageUpdate:string,
  newsInfo:{
    image:string,
    title:string,
    author:string,
    date:string,
    text:string,
    highlight:boolean,
    texthighlighted:string,
    sharing:boolean
  },
  file:string,
  messages: any[],
  updateId:number,
  deleteId: number
  modalAdd:boolean,
  modalUpdate:boolean,
  modalDelete:boolean,
  modalMultipleNews:boolean,
  messageSearch: string;
  showButtonDelete:boolean,
  searchText: string;
  searchColumn: string;
  searchTimeText;
  searchTimeRange;
  searchTimeRangeColumn: string;
  selectedRowKeys: React.Key[];
  filteredInfo;
  sortedInfo;
  columnIndex;
};

export default class News extends Component<Props, State> {
  private searchInput = React.createRef<InputRef>();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading:true,
      confirm: false,
      successful: false,
      message: "",
      successfulAdd:false,
      messageAdd:'',
      successfulDelete:false,
      messageDelete:'',
      successfulUpdate:false,
      messageUpdate:'',
      newsInfo:{
        image:"",
        title:"",
        author:"",
        date:"",
        text:"",
        highlight:false,
        texthighlighted:"",
        sharing:false
      },
      file:"",
      messages: [],
      updateId: 0,
      deleteId: 0,
      modalAdd:false,
      modalUpdate:false,
      modalDelete:false,
      modalMultipleNews:false,
      messageSearch: "",
      showButtonDelete:false,
      searchText: "",
      searchColumn: "",
      searchTimeText: null,
      searchTimeRange: null,
      searchTimeRangeColumn: "",
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      columnIndex: null,
    };
  }


  

  validationSchema() {
    return yup.object().shape({
     
    });
  }

  setUpdateId = (id) =>{
    this.setState({
      updateId: id
    })
  }

  setDeleteId = (id) =>{
    this.setState({
      deleteId:id
    })
  }


  setNewsInfo = (record) =>{
    this.setState({
      newsInfo:{
        image:"",
        title:record.title,
        author:record.author,
        date:record.date,
        text:record.text,
        highlight:record.highlight,
        texthighlighted:record.texthighlighted,
        sharing:record.sharing
      }
    })
  }

  setModalAdd = (state) =>{
    this.setState({
      modalAdd:state,
      messageAdd:"",
      successfulAdd:false
    })
  }

  setModalUpdate = (state) =>{
    this.setState({
      modalUpdate:state,
      messageUpdate:"",
      successfulUpdate:false
    })
  }

  setModalDelete = (state) =>{
    this.setState({
      modalDelete:state
    })
  }

  setModalMultiple = (state) =>{
    this.setState({
      modalMultipleNews:state
    })
  }

  validationSchemaAdd() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      title: yup.string().required("inserisci un titolo"),
      author: yup.string().required("inserisci un autore"),
      date: yup.string().required("inserisci una data"),
      text: yup.string().required("inserisci un testo"),
      texthighlighted: yup.string().required("inserisci un testo"),
    });
  }

  validationSchemaUpdate() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      title: yup.string().required("inserisci un titolo"),
      author: yup.string().required("inserisci un autore"),
      date: yup.string().required("inserisci una data"),
      text: yup.string().required("inserisci un testo"),
      texthighlighted: yup.string().required("inserisci un testo"),
    });
  }

  renderData = (news) => {
    const newsState = news.map((obj) => {
      return { key: obj.id, ...obj };
    });

    this.setState({
      messages: newsState,
    });
  };

  getAllNews = async() =>{

    newsService.getNews(
    ).then(
      response => {

        this.renderData(response.data)
        
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 500);
        
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        
    }

    );
  }

  getImage = async(id)=>{

    this.setState({
      file:''
    })


    newsService.getImage(
      id
      ).then(
        response => {

         this.setState({
           file:response.data
         })

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );

  }

  addNews = async(formValue:{image,title:string,author:string,date:string,text:string,highlight:boolean,texthighlighted:string,sharing:boolean}) =>{
    const { 
      image,
      title,
      author,
      date,
      text,
      highlight,
      texthighlighted,
      sharing
       } = formValue

       this.setState({
        messageAdd:'',
        successfulAdd:false
      })
       
      newsService.create(
         title,
         author,
         date,
         text,
         highlight,
         texthighlighted,
         sharing
        ).then(
          response => {
  
            newsService.createImage(
              image,
              response.data.id
             ).then(
               response => {
         
                 this.setState({
                   successfulAdd:true
                 })
     
                 this.getAllNews()
     
                 this.setModalAdd(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )

  }

  updateNews = async(formValue:{image,title:string,author:string,date:string,text:string,highlight:boolean,texthighlighted:string,sharing:boolean}) =>{
    const { 
      image,
      title,
      author,
      date,
      text,
      highlight,
      texthighlighted,
      sharing
       } = formValue

       this.setState({
        messageUpdate:'',
        successfulUpdate:false
      })
       
      newsService.update(
        this.state.updateId,
         title,
         author,
         date,
         text,
         highlight,
         texthighlighted,
         sharing
        ).then(
          response => {
  
            newsService.updateImage(
              image,
              this.state.updateId
             ).then(
               response => {
         
                 this.setState({
                   successfulUpdate:true
                 })
     
                 this.getAllNews()
     
                 this.setModalUpdate(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )

  }

  deleteNews = async() =>{

    newsService.delete(
        this.state.deleteId
      ).then(
        response => {
          
           this.setModalDelete(false)
           this.getAllNews()

         
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          
      }
  
      );
  }

  deleteMultipleMessage = async() =>{

    messageService.deleteMultipleMessage(
      this.state.selectedRowKeys
    ).then(
      response => {
        
         this.setModalMultiple(false)
         this.setState({
          showButtonDelete:false
         })
         this.getAllNews()

       
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        
    }

    );
  }

  componentDidMount(){
    this.getAllNews()
  }

  render() {
    const {
      successful,
      message,
      successfulAdd,
      messageAdd,
      successfulUpdate,
      messageUpdate,
      selectedRowKeys,
      filteredInfo,
      sortedInfo,
    } = this.state;

    const initialValuesAdd = {
      image:"",
      title:"",
      author:"",
      date:"",
      text:"",
      highlight:false,
      texthighlighted:"",
      sharing:true
    };

    const initialValuesUpdate = {
      image:this.state.file,
      title:this.state.newsInfo.title,
      author:this.state.newsInfo.author,
      date:this.state.newsInfo.date,
      text:this.state.newsInfo.text,
      highlight:this.state.newsInfo.highlight,
      texthighlighted:this.state.newsInfo.texthighlighted,
      sharing:this.state.newsInfo.sharing
    };

    interface DataType {
      key: string;
      id: number;
      title:string,
      author:string,
      date: string,
      text:string,
      highlight: boolean;
      valuehighlight:string,
      texthighlighted:string,
      sharing:boolean,
      valuesharing:string,
      news: any[];
    }

    type DataIndex = keyof DataType;

    const handleChange: TableProps<DataType>["onChange"] = (
      pagination,
      filters,
      sorter
    ) => {
      //console.log('Various parameters', pagination, filters, sorter)
      this.setState({
        filteredInfo: filters,
        sortedInfo: sorter as SorterResult<DataType>,
      });
    };

    const clearAll = () => {
      this.setState({ filteredInfo: {}, sortedInfo: {} });
    };

    const handleSearch = (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: DataIndex
    ) => {
      confirm();
      this.setState({ searchText: selectedKeys[0], searchColumn: dataIndex });
    };

    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      this.setState({ searchText: "" });
    };

    const getColumnSearchPropsBody = (dataIndex): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={this.searchInput}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Resetta
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: (selectedKeys as string[])[0],
                  searchColumn: dataIndex,
                });
              }}
            >
              Filtra
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Chiudi
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filteredValue: filteredInfo != null ? filteredInfo.body || null : null,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.current?.select(), 100);
        }
      },
      sortOrder:
        sortedInfo != null
          ? sortedInfo.columnKey === dataIndex
            ? sortedInfo.order
            : null
          : null,
      render: (text) =>
        this.state.searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const getColumnSearchPropsSubject = (dataIndex): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={this.searchInput}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Resetta
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: (selectedKeys as string[])[0],
                  searchColumn: dataIndex,
                });
              }}
            >
              Filtra
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Chiudi
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filteredValue: filteredInfo != null ? filteredInfo.subject || null : null,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.current?.select(), 100);
        }
      },
      sortOrder:
        sortedInfo != null
          ? sortedInfo.columnKey === dataIndex
            ? sortedInfo.order
            : null
          : null,
      render: (text) =>
        this.state.searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      this.setState({ selectedRowKeys: newSelectedRowKeys });
        if (newSelectedRowKeys.length == 0) {
          this.setState({ showButtonDelete: false });
        } else {
          this.setState({ showButtonDelete: true });
        }
    };

    const rowSelection: TableRowSelection<DataType> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
      ],
    };

    const columns: ColumnsType<DataType> = [
      {
        key: "title",
        title: "Titolo",
        dataIndex: "title",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.title.length - b.title.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "title"  ? sortedInfo.order : null : null,
      },
      {
        key:"author",
        title: "Autore",
        dataIndex: "author",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.author.length - b.author.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "author"  ? sortedInfo.order : null : null,
      },
      {
        key:"date",
        title: "Pubblicata il",
        dataIndex: "date",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        sortOrder:  sortedInfo != null ? sortedInfo.columnKey === "date"  ? sortedInfo.order  : null  : null,
        
      },
      {
        key:"text",
        title: "Testo",
        dataIndex: "text",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.text.length - b.text.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "text"  ? sortedInfo.order : null : null,
      },
      {
        key:"highlight",
        title: "testo evidenziato",
        dataIndex: ['highlight'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        filters: [
          {
            text: 'Si',
            value: true,
          },
          {
            text: 'No',
            value: false,
          },
        ],
        filterSearch: true,
        sortOrder: sortedInfo != null ? sortedInfo.columnKey === '' ? sortedInfo.order : null : null,
        onFilter: (value: any, record) => record.highlight == value,
        filteredValue: filteredInfo != null ? filteredInfo.highlight || null : null,
        render: (_, record) => {
          
          var color:string = ''

          if(record.highlight == true){
              record.valuehighlight = 'si'
              color = 'green'
           }

           if(record.highlight == false){
              record.valuehighlight = 'no'
              color = 'red'
            }


           return (
               <div>
               <Tag color={color} >
                {record.valuehighlight ? record.valuehighlight.toUpperCase() : null}
               </Tag>
             </div>
           )

        }
      },
      {
        key:"texthighlighted",
        title: "testo sottolineato",
        dataIndex: "texthighlighted",
        align: "center",
        responsive: ["md", "sm", "xs"],
        ...getColumnSearchPropsBody("texthightlighted"),
      },
      {
        key:"sharing",
        title: "Condivisione",
        dataIndex: ['sharing'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        filters: [
          {
            text: 'Si',
            value: true,
          },
          {
            text: 'No',
            value: false,
          },
        ],
        filterSearch: true,
        sortOrder: sortedInfo != null ? sortedInfo.columnKey === '' ? sortedInfo.order : null : null,
        onFilter: (value: any, record) => record.sharing == value,
        filteredValue: filteredInfo != null ? filteredInfo.sharing || null : null,
        render: (_, record) => {
          
          var color:string = ''

          if(record.sharing == true){
              record.valuesharing = 'si'
              color = 'green'
           }

           if(record.sharing == false){
              record.valuesharing = 'no'
              color = 'red'
            }


           return (
               <div>
               <Tag color={color} >
                {record.valuesharing ? record.valuesharing.toUpperCase() : null}
               </Tag>
             </div>
           )

        }
      },
      {
        title: "Azione",
        dataIndex: "azione",
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          return <>
          <Button
          onClick={() => [
            this.setModalUpdate(true),
            this.setUpdateId(record.id),
            this.setNewsInfo(record),
            this.getImage(record.id)
          ]}
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            alignContent: "center",
          }}
          icon={<EditOutlined />}
        />
          <Button
          onClick={() => [
            this.setModalDelete(true),
            this.setDeleteId(record.id),
          ]}
          danger
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            marginLeft: "20px",
            alignContent: "center",
          }}
          icon={<DeleteOutlined />}
        /></>;
        },
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Notizie</h5>

              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item text-capitalize">
                    <a href="#">Notizie</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    lista
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 mt-4"></div>
            </div>

            <div className="row text-center">

            <Space style={{ marginBottom: 16 }}>
              <div className="mobile-sm">
                <Button onClick={clearAll}>
                  Resetta ordinamenti,filtri e ricerca
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="primary" onClick={() => this.setModalAdd(true)}>
                  Aggiungi notizia
                </Button>
              </div>
              <div className="mobile-sm">
              {this.state.showButtonDelete ? (
                <Button
                  type="primary"
                  onClick={() => this.setModalMultiple(true)}
                  danger
                >
                  Elimina notizie selezionate
                </Button>
              ) : null}
              </div>
             
          </Space>
              <SkeletonTable
                loading={this.state.loading}
                columns={columns as SkeletonTableColumnsType[]}
              >
                <Table
                  columns={columns}
                  dataSource={this.state.messages}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                  rowSelection={rowSelection}
                  pagination={{
                    pageSize: 10,
                  }}
                  scroll={{ x: 600 }}
                />
              </SkeletonTable>
            </div>

            <Modal
             show={this.state.modalDelete}
             onHide={() => this.setModalDelete(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
            closeButton
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina notizia
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare questa notizia?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalDelete(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteNews()}>
              Elimina notizia
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
             show={this.state.modalMultipleNews}
             onHide={() => this.setModalMultiple(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
            closeButton
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina notizie selezionate
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare i messaggi selezionati?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalMultiple(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteMultipleMessage()}>
              Elimina notizie
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
                show={this.state.modalAdd}
                onHide={() => this.setModalAdd(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    initialValues={initialValuesAdd}
                    validationSchema={this.validationSchemaAdd}
                    onSubmit={this.addNews}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Nuova notizia
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successfulAdd
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulAdd ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>


                              <div className="mb-3">
                                  <label className="form-label">
                                    Titolo {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="title"
                                      type="text"
                                      placeholder="Titolo"
                                      className={(formik.touched.title && formik.errors.title) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.title && formik.errors.title ? (
                                        <div className="invalid-feedback">{formik.errors.title}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Autore {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="author"
                                      type="text"
                                      placeholder="Autore"
                                      className={(formik.touched.author && formik.errors.author) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.author && formik.errors.author ? (
                                        <div className="invalid-feedback">{formik.errors.author}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Data{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="date"
                                      type="date"
                                      placeholder="data"
                                      className={(formik.touched.date && formik.errors.date) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.date && formik.errors.date ? (
                                        <div className="invalid-feedback">{formik.errors.date}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Testo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="text"
                                      component="textarea"
                                      rows="4"
                                      type="text"
                                      placeholder="testo notizia"
                                      className={(formik.touched.text && formik.errors.text) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.text && formik.errors.text ? (
                                        <div className="invalid-feedback">{formik.errors.text}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                <div >
                                  <Field 
                                     name="hightlight"
                                     className="form-check-input" 
                                     type="checkbox"
                                  />
                                </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita testo in evidenza</label>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Testo sottolineato{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="texthighlighted"
                                      component="textarea"
                                      rows="4"
                                      type="text"
                                      placeholder="testo sottolineato"
                                      className={(formik.touched.texthighlighted && formik.errors.texthighlighted) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.texthighlighted && formik.errors.texthighlighted ? (
                                        <div className="invalid-feedback">{formik.errors.texthighlighted}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                <div >
                                  <Field 
                                     name="sharing"
                                     className="form-check-input" 
                                     type="checkbox"
                                  />
                                </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Consenti condivisione</label>
                          </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalAdd(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                 Pubblica
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>


        <Modal
                show={this.state.modalUpdate}
                onHide={() => this.setModalUpdate(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesUpdate}
                    validationSchema={this.validationSchemaUpdate}
                    onSubmit={this.updateNews}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Modifica notizia
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageUpdate && (
                  <div className="form-group">
                    <div
                      className={
                        successfulUpdate
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulUpdate ? "Successo - " : "Errore - "}
                      </strong>
                      {messageUpdate}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>


                              <div className="mb-3">
                                  <label className="form-label">
                                    Titolo {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="title"
                                      type="text"
                                      placeholder="Titolo"
                                      className={(formik.touched.title && formik.errors.title) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.title && formik.errors.title ? (
                                        <div className="invalid-feedback">{formik.errors.title}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Autore {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="author"
                                      type="text"
                                      placeholder="Autore"
                                      className={(formik.touched.author && formik.errors.author) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.author && formik.errors.author ? (
                                        <div className="invalid-feedback">{formik.errors.author}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Data{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="date"
                                      type="date"
                                      placeholder="data"
                                      className={(formik.touched.date && formik.errors.date) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.date && formik.errors.date ? (
                                        <div className="invalid-feedback">{formik.errors.date}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Testo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="text"
                                      component="textarea"
                                      rows="4"
                                      type="text"
                                      placeholder="testo notizia"
                                      className={(formik.touched.text && formik.errors.text) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.text && formik.errors.text ? (
                                        <div className="invalid-feedback">{formik.errors.text}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                <div >
                                  <Field 
                                     name="hightlight"
                                     className="form-check-input" 
                                     type="checkbox"
                                  />
                                </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita testo in evidenza</label>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Testo sottolineato{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="texthighlighted"
                                      component="textarea"
                                      rows="4"
                                      type="text"
                                      placeholder="testo sottolineato"
                                      className={(formik.touched.texthighlighted && formik.errors.texthighlighted) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.texthighlighted && formik.errors.texthighlighted ? (
                                        <div className="invalid-feedback">{formik.errors.texthighlighted}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                <div >
                                  <Field 
                                     name="sharing"
                                     className="form-check-input" 
                                     type="checkbox"
                                  />
                                </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Consenti condivisione</label>
                          </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalUpdate(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                Modifica
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>

          </div>
        </div>
      </>
    );
  }
}
