import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import Loginimg from "../../assets/img/login.svg";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import authService from "../../api/auth/auth.service";
import TokenService from "../../api/token.service";

type Props = {};

type State = {
  showPassword:boolean,
  confirm:boolean,
  successful: boolean;
  message: string;
};

export default class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPassword: false,
      confirm: false,
      successful: false,
      message: "",
    };
  }

  togglePassword = () =>{
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  validationSchema() {
    return yup.object().shape({
      email: yup.string().required("inserisci un'email"),
      password: yup.string().required("la password non può essere vuota"),
    });
  }

  login = async (formValue:{email: string, password: string}) => {
        const {email,password} = formValue
       
        this.setState({
          message: ""
        })

        authService.login(
          email,
          password
        ).then(
          response => {
            
    
            if (response.data.accessToken || response.data.refreshToken) {
              TokenService.setAccessToken(response.data.accessToken)
              TokenService.setRefreshToken(response.data.refreshToken)
            }

              this.setState({
                successful:true,
              })
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
              if(resMessage == "incorrect-email-or-password"){
                this.setState({
                  successful: false,
                  message: "Email o password sbagliati"
                });
              } 
              
    
               if(resMessage == "account-suspended"){
                this.setState({
                  successful: false,
                  message: "Il tuo account è stato sospeso contatta l'amministratore per saperne le motivazioni"
                });
             }
    
             if(resMessage == "account-banned"){
              this.setState({
                successful: false,
                message: "Il tuo account è stato bannato contatta l'amministratore per saperne le motivazioni"
              });
           }
    
          if(resMessage == "account-not-verified"){
              localStorage.setItem('scope','email')
               this.setState({
                confirm:true
               })
               
          }
    
    
    
        }
    
          
        );
    
        

  };

  render() {
    const { successful, message } = this.state;

    const initialValues = {
      email: "",
      password: "",
    };

    return (
      <>

          {this.state.successful ? <Navigate to='/dashboard/admin/messages' /> : null}

          {this.state.confirm ? <Navigate to='../confirm-account' replace={true} /> : null}


        <div className="back-to-home">
          <NavLink to={"../"} className="back-button btn btn-icon btn-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-arrow-left icons"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </NavLink>
        </div>


        <section className="bg-home d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="me-lg-5">
                  <img
                    src={Loginimg}
                    className="img-fluid d-block mx-auto"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="card login-page shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-center">Accedi</h4>
                    {message && (
                        <div className="form-group">
                          <div
                            className={
                              successful
                                ? "alert alert-success alert-dismissible fade show"
                                : "alert alert-danger alert-dismissible fade show "
                            }
                            role="alert"
                          >
                            <button
                              type="button"
                              className="btn-close btn-close-white"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                            <strong>Errore - </strong>

                            {message}
                          </div>
                        </div>
                      )}

                    <Formik
                      initialValues={initialValues}
                      validationSchema={this.validationSchema}
                      onSubmit={this.login}
                    >
                      

                      {formik =>(

                      <Form className="login-form mt-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Eamil{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-user fea icon-sm icons"
                                >
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                <Field
                                  name="email"
                                  type="email"
                                  placeholder="Email"
                                  className={(formik.touched.email && formik.errors.email) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                  /> 
                                  {formik.touched.email && formik.errors.email ? (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null} 
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Password <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-key fea icon-sm icons"
                                >
                                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                </svg>
                                <Field
                                  name="password"
                                  type={ this.state.showPassword ? "text" : "password"}
                                  placeholder="Password"
                                  className={(formik.touched.password && formik.errors.password) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                  /> 
                                  {formik.touched.password && formik.errors.password ? (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    ) : null} 
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="d-flex justify-content-between">
                            <div className="mb-3">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" onClick={this.togglePassword} />
                                  <label className="form-check-label" >mostra password</label>
                                </div>
                            </div>
                              <p className="forgot-pass mb-0">
                                <NavLink
                                  to={"../forgot"}
                                  className="text-dark fw-bold"
                                >
                                  Password dimenticata?
                                </NavLink>
                              </p>
                            </div>
                          </div>

                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button className="btn btn-primary">
                                Accedi
                              </button>
                            </div>
                          </div>

                          {/*<div className="col-lg-12 mt-4 text-center">
                            <h6>Or Login With</h6>
                            <div className="row">
                              <div className="col-6 mt-3">
                                <div className="d-grid">
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-light"
                                  >
                                    <i
                                      style={{ color: "blue" }}
                                      className="uil uil-facebook-f"
                                    ></i>{" "}
                                    Facebook
                                  </a>
                                </div>
                              </div>

                              <div className="col-6 mt-3">
                                <div className="d-grid">
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-light"
                                  >
                                    <i
                                      style={{ color: "red" }}
                                      className="uil uil-google"
                                    ></i>{" "}
                                    Google
                                  </a>
                                </div>
                              </div>
                            </div>
                                  </div>*/}

                          {/*<div className="col-12 text-center">
                            <p className="mb-0 mt-3">
                              <small className="text-dark me-2">
                                Don't have an account ?
                              </small>{" "}
                              <a
                                href="auth-signup.html"
                                className="text-dark fw-bold"
                              >
                                Sign Up
                              </a>
                            </p>
                         </div>*/}
                        </div>
                      </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
