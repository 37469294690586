import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import authService from "../../api/auth/auth.service";
import TokenService from "../../api/token.service";
import Recovery from "../../assets/img/recovery.svg";

type Props = {};

type State = {
  confirm: boolean;
  successful: boolean;
  message: string;
};

export default class VerifyOtp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirm: false,
      successful: false,
      message: "",
    };
  }

  validationSchema() {
    return yup.object().shape({
      otp: yup.string().required("inserisci il codice otp"),
    });
  }

  verify = async (formValue: { otp: string }) => {
    const { otp } = formValue;
    console.log(formValue);

     this.setState({
        message: "",
        successful:false,
      })

    authService.confirmOtp(
        otp
    ).then(
        response => {
         
            TokenService.setAccessToken(response.data.accessToken)

            this.setState({
              successful:true,
            })
            
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
    
            if(resMessage == "otp-code-not-found"){
                this.setState({
                  successful: false,
                  message: "Il codice inserito è errato"
                });
              } 
      }
  
        
      );

    
  };

  render() {
    const { successful, message } = this.state;

    const initialValues = {
      otp: "",
    };

    return (
      <>

         {this.state.successful == true ? <Navigate to='../reset-password' replace={true} /> : null}

        <div className="back-to-home">
          <NavLink
            to={"../forgot"}
            className="back-button btn btn-icon btn-primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-arrow-left icons"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </NavLink>
        </div>

        <section className="bg-home d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="me-lg-5">
                  <img
                    src={Recovery}
                    className="img-fluid d-block mx-auto"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-center">Verifica</h4>
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success alert-dismissible fade show"
                              : "alert alert-danger alert-dismissible fade show "
                          }
                          role="alert"
                        >
                          <button
                            type="button"
                            className="btn-close btn-close-white"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                          <strong>Errore - </strong>

                          {message}
                        </div>
                      </div>
                    )}

                   <Formik
                      initialValues={initialValues}
                      validationSchema={this.validationSchema}
                      onSubmit={this.verify}
                    >

                {formik => (

                    <Form className="login-form mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="text-muted">
                            Inserisci il codice otp ricevuto via email
                          </p>
                          <div className="mb-3">
                            <label className="form-label">
                              Otp{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-user fea icon-sm icons"
                                >
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                <Field
                                  name="otp"
                                  type="text"
                                  placeholder="Otp"
                                  className={(formik.touched.otp && formik.errors.otp) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                  /> 
                                  {formik.touched.otp && formik.errors.otp ? (
                                        <div className="invalid-feedback">{formik.errors.otp}</div>
                                    ) : null} 
                              </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="btn btn-primary">Verifica codice</button>
                          </div>
                        </div>
                      </div>
                    </Form>
                     )}
                  </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
