import React from "react";
import { Route, Navigate, useLocation, matchRoutes, matchPath} from "react-router-dom";
import TokenService from "../../../api/token.service";

 
  function Redirect({ children }) {

    const location = useLocation()
    console.log(location)

    if(TokenService.getAccessToken()){

      if(TokenService.getDataAccessToken().type == "user" ){

       if(matchPath("/dashboard/", location.pathname) || matchPath("/dashboard/users/", location.pathname)){
        return (
            <>
              <Navigate to={'./user/profile'} replace={true}/>
              {children}
            </>
        )
        }
      }

      if(TokenService.getDataAccessToken().type == "admin"){

        if(matchPath("/dashboard/", location.pathname) || matchPath("/dashboard/admin/", location.pathname)){
          return (
            <>
              <Navigate to={'./admin/messages'} replace={true}/>
              {children}
            </>
        )
        }
        
      }

      if(TokenService.getDataAccessToken().type == "employee" ){
        
       if(matchPath("/dashboard/", location.pathname) || matchPath("/dashboard/employee/", location.pathname)){
        return (
             <>
              <Navigate to={'./employee/profile'} replace={true}/>
              {children}
            </>
        )
       }
      }
         
      return children
    }else{
      return <Navigate to={'../login'} replace={true}/>
    }
    
  }

export default Redirect
