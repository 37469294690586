import React, { createRef, StyleHTMLAttributes } from "react";
import { Navigate, NavLink, redirect} from 'react-router-dom';
import { Component } from "react";
import Error404 from '../../assets/img/error/404.svg'


type Props = {};

type State = {
  
};

export default class Dashboard404error extends Component<Props, State> {
  

  constructor(props: Props) {
    super(props);
 
    
    this.state = {
      
    };
  }



 

  componentDidMount(){
     
  }

  render(){

    const {} = this.state;


      
    return (

     <>
        
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Errore</h5>

              <nav
                aria-label="breadcrumb"
                className="d-inline-block mt-2 mt-sm-0"
              >
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    404
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
             
                
            </div>

            <section className="d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 text-center">
                        <img src={Error404} className="img-fluid" alt=""/>
                        <div className="text-uppercase mt-4 display-3">Oh ! no</div>
                        <div className="text-capitalize text-dark mb-4 error-page">Pagina non trovata</div>
                        {/*<p className="text-muted para-desc mx-auto">Start working with <span className="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>*/}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">  
                        {/*<a href="index.html" className="btn btn-outline-primary mt-4">Go Back</a>*/}
                        <NavLink to={'/dashboard/'} className="btn btn-primary mt-4 ms-2">Ritorna alla home</NavLink>
                    </div>
                </div>
            </div>
        </section>
      </div>
    </div>
        
       
     </>

    )
  }
}