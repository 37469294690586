import React, { useEffect, useState } from "react";
import userService from "../../../api/user/user.service";

const UserImage = ({ reload }) => {
  const [preview, setPreview] = useState<any>()


  useEffect(() => {
    userService.getImage(
    ).then(
      response => {

        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );

  }, [reload]);



  return (
    <img
      src={preview}
      className="avatar float-md-left avatar-medium rounded-circle shadow me-md-4"
      alt=""
    />
  )
}

export default UserImage  