import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import authService from "../../api/auth/auth.service";
import TokenService from "../../api/token.service";
import Recovery from "../../assets/img/recovery.svg";

type Props = {};

type State = {
  showPassword:boolean,
  successful: boolean;
  message: string;
};

export default class ResetPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPassword:false,
      successful: false,
      message: "",
    };
  }


  togglePassword = () =>{
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  validationSchema() {

    const passwordRules = /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/
    return yup.object().shape({
      password: yup.string().required("inserisci una password").matches(passwordRules, 'Inserisci una password di almeno 8 caratteri, una maiuscuola , simboli speciali e almeno 2 numeri'),
      cpassword: yup.string().oneOf([yup.ref('password')], 'le password non corrispondono').required('inserisci la password di conferma'),
    })
  }


  resetStorage(){
    TokenService.clear()
  }

  changePassword = async (formValue: { password: string, cpassword: string }) => {
    const { password,cpassword } = formValue;


    authService.resetPassword(
        password,
        cpassword
    ).then(
        response => {

          TokenService.clear()

          this.setState({
            successful:true
          })
          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
    
            if(resMessage == "otp-not-verificated"){
              this.setState({
                successful: false,
                message: "Il codice otp non è stato verificato"
              });
            } 

            if(resMessage == "account-not-verificated"){
              this.setState({
                successful: false,
                message: "l'account non è stato verificato"
              });
            } 
      }
  
        
      );
  };

  render() {
    const { successful, message } = this.state;

    const initialValues = {
      password: "",
      cpassword: ""
    };

    return (
      <>

        {this.state.successful == true ? <Navigate to='../login' replace={true} /> : null}


        <section className="bg-home d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="me-lg-5">
                  <img
                    src={Recovery}
                    className="img-fluid d-block mx-auto"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-center">Cambia password</h4>
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success alert-dismissible fade show"
                              : "alert alert-danger alert-dismissible fade show "
                          }
                          role="alert"
                        >
                          <button
                            type="button"
                            className="btn-close btn-close-white"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                          <strong>Errore - </strong>

                          {message}
                        </div>
                      </div>
                    )}

                   <Formik
                      initialValues={initialValues}
                      validationSchema={this.validationSchema}
                      onSubmit={this.changePassword}
                    >

                {formik => (

                    <Form className="login-form mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="text-muted">
                            Inserisci una nuova password.
                          </p>
                          <div className="mb-3">
                            <label className="form-label">
                              Nuova password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                            <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-key fea icon-sm icons"
                                >
                                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                </svg>
                                <Field
                                  name="password"
                                  type={ this.state.showPassword ? "text" : "password"}
                                  placeholder="password"
                                  className={(formik.touched.password && formik.errors.password) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                  /> 
                                  {formik.touched.password && formik.errors.password ? (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    ) : null} 
                              </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Conferma password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                            <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-key fea icon-sm icons"
                                >
                                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                </svg>
                                <Field
                                  name="cpassword"
                                  type={ this.state.showPassword ? "text" : "password"}
                                  placeholder="conferma password"
                                  className={(formik.touched.cpassword && formik.errors.cpassword) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                  /> 
                                  {formik.touched.cpassword && formik.errors.cpassword ? (
                                        <div className="invalid-feedback">{formik.errors.cpassword}</div>
                                    ) : null} 
                              </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between">
                            <div className="mb-3">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" onClick={this.togglePassword} />
                                  <label className="form-check-label" >mostra password</label>
                                </div>
                            </div>
                            </div>
                          </div>

                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="btn btn-primary">Reimposta password</button>
                          </div>
                        </div>
                        <div className="mx-auto">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              Ricordi la password?
                            </small>{" "}
                            <NavLink
                              onClick={this.resetStorage}
                              to={"../login"}
                              className="text-dark fw-bold"
                            >
                              Accedi
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </Form>
                     )}
                  </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
