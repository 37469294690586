import React, { createRef, StyleHTMLAttributes } from "react";
import { Component } from "react";
import * as yup from "yup";
import { Button, Form, Input, Skeleton, Switch, Tabs, TabsProps } from "antd";
import aboutmeService from "../../../api/page/aboutme/aboutme.service";
import { Field, Formik } from "formik";
import * as Forms from "formik";
import PreviewImage from "../element/previewImage";
import homeService from "../../../api/page/home/home.service";

type Props = {};

type State = {
  message: string;
  successful: boolean;
  updateid: number;
  loading: boolean;
  file:string,
  name:string,
  surname:string,
  urlvideo:string,
  description: string;
  birthdayinfo: boolean;
  birthday: string;
  ageinfo: boolean;
  age: string;
  locationinfo: boolean;
  location: string;
  interestsinfo: boolean;
  interests: string;
  studyinfo: boolean;
  study: string;
  degreeinfo: boolean;
  degree: string;
  emailinfo: boolean;
  email: string;
  phoneinfo: boolean;
  phone: string;
};

export default class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      successful: false,
      updateid: 1,
      loading: true,
      file:"",
      name:"",
      surname:"",
      urlvideo:"",
      description: "",
      birthdayinfo: false,
      birthday: "",
      ageinfo: false,
      age: "",
      locationinfo: false,
      location: "",
      interestsinfo: false,
      interests: "",
      studyinfo: false,
      study: "",
      degreeinfo: false,
      degree: "",
      emailinfo: false,
      email: "",
      phoneinfo: false,
      phone: "",
    };
  }

  validationSchema() {
    const supported_formats = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/svg",
      "image/ips",
      "image/psd",
      "image/ai",
    ];
    return yup.object().shape({
      image: yup
        .mixed()
        .nullable()
        .required("inserisci un file di immagine")
        .test(
          "type",
          "Il file caricato non è un'immaggine",
          (values) =>
            !values || (values && supported_formats.includes(values["type"]))
        ),
    });
  }

  getSettings = async () => {
    homeService.getSettings().then(
      (response) => {

     if(response.data[0]){
        this.setState({
          name: response.data[0].name,
          surname: response.data[0].surname,
          urlvideo: response.data[0].urlvideo,
        });
     }

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  updateSettings = async (values) => {
    this.setState({
      message: "",
      successful: false,
    });

    homeService
      .updateSettings(
        this.state.updateid,
        values.name,
        values.surname,
        values.urlvideo,
      )
      .then(
        (response) => {
          this.setState({
            message: "Impostazioni salvate",
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };


  getImage = async() =>{

    this.setState({
      file:''
    })

    homeService
    .getImage(
      this.state.updateid
    )
    .then(
      (response) => {

        this.setState({
          file:response.data
        })
        
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  updateImage = async (formValue:{image}) => {

    const { 
      image,
       } = formValue


    homeService
    .updateImage(
      image,
      this.state.updateid
    )
    .then(
      (response) => {
        
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  componentDidMount() {
    this.getImage()
    this.getSettings();
  }

  render() {
    const { message, successful } = this.state;

    const initialValues = {
      image: this.state.file ? this.state.file : '',
    };

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: `Immagine`,
        children: (
          <>
            <Forms.Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.updateImage}
            >
              {(formik) => (
                <Forms.Form>
                  <div className="d-grid me-md-4" style={{width:'346px'}}>
                    <p className="text-muted">
                      Carica un'immagine qui. Clicca sul pulsante "Carica
                      immagine".
                    </p>
                    {formik.values.image != "" ? (
                      <PreviewImage file={formik.values.image} />
                    ) : null}
                    <input
                      type="file"
                      id="input-file"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={(event) => {
                        //console.log(event.target.files)
                        formik.setFieldValue("image", event.target.files![0]);
                      }}
                      className={
                        formik.touched.image && formik.errors.image
                          ? "form-control ps-5 is-invalid"
                          : "form-control ps-5"
                      }
                    />
                    {formik.touched.image && formik.errors.image ? (
                      <div className="invalid-feedback">
                        {formik.errors.image}
                      </div>
                    ) : null}
                    <label
                      className="btn-upload btn btn-primary mt-4"
                      htmlFor="input-file"
                    >
                      Carica immagine
                    </label>
                    <Button type="primary" htmlType="submit" style={{width:'346px'}}>
                    Aggiungi
                    </Button>
                  </div>
                  
                </Forms.Form>
              )}
            </Forms.Formik>
          </>
        ),
      },
      {
        key: "2",
        label: `Impostazioni`,
        children: (
          <>
            {this.state.loading ? (
              <Skeleton loading={this.state.loading} active></Skeleton>
            ) : (
              <Form
                onFinish={this.updateSettings}
                initialValues={{
                  name: this.state.name,
                  surname: this.state.surname,
                  urlvideo: this.state.urlvideo
                }}
              >
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success alert-dismissible fade show"
                          : "alert alert-danger alert-dismissible fade show "
                      }
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>

                      {message}
                    </div>
                  </div>
                )}
                
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Nome</label>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un nome",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un nome" />
                    </Form.Item>
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Cognome</label>
                    <Form.Item
                      name="surname"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci cognome",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci cognome" />
                    </Form.Item>
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Link video:</label>
                    <Form.Item
                      name="urlvideo"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un link",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un link" />
                    </Form.Item>
                  </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "30px" }}
                  >
                    Salva
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ),
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Impostazioni home</h5>

              <nav
                aria-label="breadcrumb"
                className="d-inline-block mt-2 mt-sm-0"
              >
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Home
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row" style={{ marginTop: "30px" }}>
              <Tabs defaultActiveKey="2" items={items} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
