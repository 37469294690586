import React, { createRef, StyleHTMLAttributes } from "react";
import { Component } from "react";
import * as yup from "yup";
import { Button, Form, Input, Skeleton, Switch, Tabs, TabsProps } from "antd";
import aboutmeService from "../../../api/page/aboutme/aboutme.service";
import { Field, Formik } from "formik";
import * as Forms from "formik";
import PreviewImage from "../element/previewImage";
import homeService from "../../../api/page/home/home.service";
import pageService from "../../../api/page/page.service";
import statisticsService from "../../../api/page/statistics/statistics.service";

type Props = {};

type State = {
  message: string;
  successful: boolean;
  updateid: number;
  loading: boolean;
  customers:string,
  projects:string,
  ongoing:string,
  experience:string,
};

export default class Statistics extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      successful: false,
      updateid: 1,
      loading: true,
      customers:"",
      projects:"",
      ongoing:"",
      experience:"",
    };
  }

  

  getSettings = async () => {
    statisticsService.getSettings().then(
      (response) => {

     if(response.data[0]){
        this.setState({
          customers: response.data[0].customers,
          projects: response.data[0].projects,
          ongoing: response.data[0].ongoing,
          experience: response.data[0].experience
        });
     }

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  updateSettings = async (values) => {
    this.setState({
      message: "",
      successful: false,
    });

    statisticsService
      .updateSettings(
        this.state.updateid,
        values.customers,
        values.projects,
        values.ongoing,
        values.experience,
      )
      .then(
        (response) => {
          this.setState({
            message: "Impostazioni salvate",
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  };





  componentDidMount() {
    this.getSettings();
  }

  render() {
    const { message, successful } = this.state;


    const items: TabsProps["items"] = [
      {
        key: "1",
        label: `Impostazioni`,
        children: (
          <>
            {this.state.loading ? (
              <Skeleton loading={this.state.loading} active></Skeleton>
            ) : (
              <Form
                onFinish={this.updateSettings}
                initialValues={{
                   customers: this.state.customers,
                   projects: this.state.projects,
                   ongoing: this.state.ongoing,
                   experience: this.state.experience,
                }}
              >
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success alert-dismissible fade show"
                          : "alert alert-danger alert-dismissible fade show "
                      }
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>

                      {message}
                    </div>
                  </div>
                )}
                
                <div className="mb-3" style={{ width: "300px" }}>
                    <label>Clienti soddisfatti</label>
                    <Form.Item
                      name="customers"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci clienti soddisfatti",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci clienti soddisfatti" />
                    </Form.Item>
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Progetti finiti</label>
                    <Form.Item
                      name="projects"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci progetti finiti",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci progetti finiti" />
                    </Form.Item>
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Progetti in corso</label>
                    <Form.Item
                      name="ongoing"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci progetti in corso",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci progetti in corso" />
                    </Form.Item>
                  </div>
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Anni di esperienza</label>
                    <Form.Item
                      name="experience"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci anni di esperinza",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci anni di esperienza" />
                    </Form.Item>
                  </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "30px" }}
                  >
                    Salva
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ),
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Impostazioni statistiche</h5>

              <nav
                aria-label="breadcrumb"
                className="d-inline-block mt-2 mt-sm-0"
              >
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Impostazioni statistiche
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row" style={{ marginTop: "30px" }}>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
